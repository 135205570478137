import React, { Component } from "react"
import Img from "gatsby-image"

export default class Dropdown extends Component {
    constructor(props) {
      super(props)
      this.change = this.change.bind(this)
      this.state = {
        status: 'after',
      }
    }
    componentDidMount() {
      this.setState({ status: 'after' })
    }
    change() {
        let statusV = this.state.status === 'before' ? 'after' : 'before'
        this.setState({ status: statusV })
    }
    render() {
      return (
        <div className="Img-Switcher" data-status={this.state.status}>
        <div className="image after">
          <Img fluid={this.props.after}></Img>
        </div>
        <div className="image before">
          <Img fluid={this.props.before}></Img>
        </div>
      </div>
      )
    }
  }

