import React, { Component } from "react"

export default class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.collapse = this.collapse.bind(this)
    this.state = {
      collapsed: false,
    }
  }
  componentDidMount() {
    const maxheightstate = this.child.clientHeight
    this.setState({ maxHeight: maxheightstate, height:0 })
  }
  collapse() {
    let collapsedState = this.state.collapsed === true ? false : true
    let heightState = '0.01px'
    if (collapsedState) {
      heightState = this.state.maxHeight
    }
    this.setState({
      collapsed: collapsedState,
      height: heightState,
    })
    return false;
  }
  render() {
    return (
      <dl className="component-dropdown" data-collapsed={this.state.collapsed}>
        <dt><button style={{appearance:'none',border:'none',background:'none',outline:'none',cursor:'pointer'}} onClick={this.collapse}>{this.props.heading}</button></dt>
        <dd style={{maxHeight: this.state.height,overflow:'hidden'}}>
          <div
            ref={child => {
              this.child = child
            }}
          >
            {this.props.children}
          </div>
        </dd>
      </dl>
    )
  }
}
