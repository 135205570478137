import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Image = (props) => {
    const data = useStaticQuery(graphql`
      query {
         image: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
            edges {
                node {
                  extension
                  relativePath
            childImageSharp {
               fluid(maxWidth: 300) {
                   ...GatsbyImageSharpFluid
                }
               }
           }
        }
    }
        }
     `)
    const image = data.image.edges.find(
        image => image.node.relativePath === props.path
    )
    return (
        <Img
            className="image"
            fluid={image.node.childImageSharp.fluid}
        />
    )
}

export default Image
